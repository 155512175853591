<template>
  <div class="mb-20 px-4 md:px-16">
    <section class="md:max-w-[1560px] mx-auto">
      <div
        class="bg-white py-10 flex w-full items-center flex-col rounded-t-lg lg:py-12"
      >
        <div
          class="bg-white shadow-icone rounded-full w-[70px] h-[70px] flex justify-center items-center mb-8 lg:w-[135px] lg:h-[135px]"
        >
          <inline-svg
            class="mr-1 w-8 lg:w-14"
            :src="require('@/assets/novo/handshake.svg')"
            title="Aperto de Mão"
          />
        </div>
        <div
          class="text-center px-8 text-[#727272] lg:text-lg lg:px-20 xl:px-48"
        >
          <p class="text-[#0B5E67] mb-4">
            <span> Bem-Vindo ao Programa </span>
            <span class="font-bold text-lg -mt-1 block lg:text-xl">
              RAStrear Câncer Colorretal
            </span>
          </p>
          <p class="mb-3">
            Após realização dos testes RAS/BRAF, utilizados para diagnóstico do
            câncer colorretal metastático, é possível monitorar em tempo real o
            <em>status</em> do pedido, proporcionando mais facilidade e
            segurança aos participantes do programa.
          </p>
          <p class="mb-3">
            Através do programa
            <strong class="text-[#0B5E67]">RAStrear Câncer Colorretal</strong>,
            otimiza-se tempo, o bem mais valioso para tomadas de decisão e
            estratégias de tratamento.
          </p>
        </div>
      </div>
    </section>
    <section
      class="px-6 pt-8 pb-14 bg-[#0D595D] md:max-w-[1560px] md:px-8 mx-auto lg:text-lg lg:px-28 xl:px-56"
    >
      <div class="text-center text-white font-light">
        <inline-svg
          aria-label="Informações"
          class="inline-block"
          :src="require('@/assets/novo/info.svg')"
          title="Informações"
        />
        <p class="text-white text-center font-bold mb-4">
          SOBRE OS TESTES RAS/BRAF
        </p>
        <p class="mb-4">
          O Exame tem como objetivo a identificação molecular do status
          mutacional de RAS (KRAS e NRAS) incluindo o KRAS G12C: éxon 2 (códons
          12/13), éxon 3 (códon 61); éxon 4 (códons 117/146) e NRAS éxon 2
          (códons 12/13), éxon 3 (códon 61) e éxon 4 (códons 117/146) e BRAF
          V600E.
        </p>
      </div>
    </section>
    <section class="mb-6 -mt-4 md:-mt-7 lg:-mt-8">
      <BigButton
        class="flex justify-center w-full px-6 md:max-w-[432px] md:mx-auto"
      />
    </section>
  </div>
</template>

<script>
import BigButton from "@/components/molecules/BigButton.vue";

export default {
  name: "SobreOPrograma",
  components: { BigButton },
};
</script>
